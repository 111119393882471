<div class="container-fluid">
  <div *ngIf="waitingForResponse" class="overlay">
    <app-ae-loading-indicator [isLoading]="waitingForResponse" [type]="'loadingIndicator'"></app-ae-loading-indicator>
  </div>
  <div *ngIf="!isLlmProgram">
    <div class="row align-items-center">
      <div class="col">
        <h2 class="mb-0">Simulate Events</h2>
      </div>
      <div class="col-auto" *ngIf="authorizationService.isAllowed('PKG_PRG', ['CREATE','UPDATE']) || authorizationService.isAllowed('CUST_PRG', ['CREATE','UPDATE'])">
        <app-ae-button [buttonText]="'Run Simulation'" buttonType="primary" (click)="performRunSimulation()"></app-ae-button>
      </div>
      <div class="col-12">
        <p class="my-md">{{displayText.PROGRAM_SIMULATE_DESCRIPTION}}</p>
      </div>
    </div>
    <ng-container *ngFor="let _ of eventsToBeSimulated; let i=index">
      <ng-container *ngIf="eventsToBeSimulated[i].recordAdded; else addEventRecordTemplate">
        <div class="row">
          <div class="col col-md-8">
            <div class="form-group">
              <label for="eventRecord">Event Record</label>
              <textarea
                id="eventRecord"
                class="form-control font-size-lg"
                rows="4"
                [(ngModel)]="eventsToBeSimulated[i].sample"
                [class.is-invalid]="eventsToBeSimulated[i].errors"
              ></textarea>
              <div *ngIf="eventsToBeSimulated[i].errors" class="text-color-warning">
                <ng-container *ngIf="eventsToBeSimulated[i].errors && eventsToBeSimulated[i].errors.type==='object'">
                  Your event record is invalid. Please check for syntax errors.
                </ng-container>
                <ng-container *ngIf="eventsToBeSimulated[i].errors && eventsToBeSimulated[i].errors.type==='required'">
                  <div>Please enter the required fields:</div>
                  <ul>
                    <li *ngFor="let reqField of eventsToBeSimulated[i].errors.data">
                      {{reqField}}
                    </li>
                  </ul>
                </ng-container>
                <ng-container *ngIf="eventsToBeSimulated[i].errors && eventsToBeSimulated[i].errors.type==='validation'">
                  <div>Please correct the validation errors:</div>
                  <ul>
                    <li *ngFor="let reqField of eventsToBeSimulated[i].errors.data">
                      {{reqField}}
                    </li>
                  </ul>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-auto mt-md pl-0">
            <app-ae-button [buttonText]="'View Record'" (click)="open(i)"></app-ae-button>
            <app-ae-button [buttonText]="'Delete'" class="ml-2" (click)="discardEventRecordAt(i)"></app-ae-button>
          </div>
        </div>
      </ng-container>
      <ng-template #addEventRecordTemplate>
        <div class="row">
          <div class="col-6 col-md-4 col-lg-3">
            <div class="form-group">
              <label for="addEventRecord">Add Event Record</label>
              <coreui-select [(ngModel)]="eventRecordType[i]" id="addEventRecord" [class.is-invalid]="eventsToBeSimulated[i].errors"
                placeholder="Select an event record">
                <coreui-option value="null" [disabled]="true">Select an event record</coreui-option>
                <coreui-option *ngFor="let rule of programEventRules" [value]="rule">{{rule.eventName}}</coreui-option>
              </coreui-select>
              <div
                *ngIf="eventsToBeSimulated[i].errors && eventsToBeSimulated[i].errors.type==='notAdded'"
                class="text-error"
              >
                This event record is not added
              </div>
            </div>
          </div>
          <div class="col-auto pl-0 mt-md" *ngIf="authorizationService.isAllowed('PKG_PRG', ['CREATE','UPDATE']) || authorizationService.isAllowed('CUST_PRG', ['CREATE','UPDATE'])">
            <app-ae-button [buttonText]="'Add'" class="mt-1" (click)="addToSimulateEventList(i)" [disabled]="eventRecordType[i] == null || eventRecordType[i] == ''"></app-ae-button>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <div class="row" *ngIf="authorizationService.isAllowed('PKG_PRG', ['CREATE','UPDATE']) || authorizationService.isAllowed('CUST_PRG', ['CREATE','UPDATE'])">
      <div class="col">
        <app-ae-button icon="add" iconPosition="left" buttonType="text-primary" [buttonText]="'Add another event record'" class="p-0" (click)= "addNewEventRecord()" [disabled]="eventsToBeSimulated.length >= ALLOWED_NUMBER_OF_EVENT_RECORDS"></app-ae-button>
      </div>
    </div>
  </div>
  <div *ngIf="isLlmProgram" class="chat-container">
    <div class="chat-window">
      <div *ngFor="let message of messages" [ngClass]="{'user-message': message.sender === 'user', 'api-message': message.sender === 'api'}">
    
        <!-- Display string messages -->
        <!-- <span *ngIf="message.type == 'string'">{{ message.text }}</span> -->
        <span *ngIf="message.type == 'string'" [innerHTML]="message.text"></span>
        
        <!-- Display image messages-->
        <img *ngIf="message.type == 'image'" [src]="message.text" width="300" height="250">
        <!-- Display charts -->
        <canvas *ngIf="message.type=='chart'" [id]="message.id" style="width:100%;max-width:600px"></canvas>
        
        <!-- Display tables dynamically -->
        <table *ngIf="message.type == 'table' && message.table && message.table.length > 0" [id]="message.tableId" style="width: 50%; border-collapse: collapse; margin-top: 10px;">
          <thead>
            <tr>
              <!-- Dynamically generate table headers based on the keys in the first row -->
              <th *ngFor="let key of message.table[0] | keyvalue" style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: #f2f2f2;">
                {{ key.key }}
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Dynamically generate table rows and cells -->
            <tr *ngFor="let row of message.table">
              <td *ngFor="let key of row | keyvalue" style="border: 1px solid #ddd; padding: 8px; text-align: left;">
                {{ key.value }}
              </td>
            </tr>
          </tbody>
        </table>
    
      </div>
      <div *ngIf="loading" class="spinner-container">
        <div class="spinner"></div>
      </div>
    </div>
    <div class="input-area">
        <input type="text" [(ngModel)]="userInput" placeholder="Type your message here..." (keydown.enter)="sendMessage()"/>
        <button (click)="sendMessage()">Send</button>
        <button (click)="clearMessages()">Clear</button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <ng-container *ngIf="eventsToBeSimulated.length >= ALLOWED_NUMBER_OF_EVENT_RECORDS">
        <p class="text-color-warning">{{displayText.ALLOWED_EVENT_RECORDS_EXHAUSTED}}</p>
      </ng-container>
    </div>
  </div>
</div>
<app-ae-modal-info></app-ae-modal-info>
