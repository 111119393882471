import { Component, OnDestroy, OnInit, Injector, ViewChild } from '@angular/core';
import { DisplayTextService } from 'src/app/shared/services/display-text.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import { EventRecord, ProgramSharedDataService } from '../program-shared-data.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { ProgramSimulationEvent } from 'src/app/shared/models/program-simulation-event';
import { ProgramData } from 'src/app/shared/models/program-data';
import { ConfiguredProgram } from 'src/app/shared/models/configured-program';
import { ProgramType } from 'src/app/shared/program-type';
import { EventKey } from 'src/app/shared/models/eventKey.model';
import { PackagedProgramService } from 'src/app/shared/services/packaged-program.service';
import { EventSource } from 'src/app/shared/event-source';
import { JSONFileService } from 'src/app/shared/services/json-file-service';
import * as Ajv from 'ajv';
import { AEModalInfoComponent } from 'src/app/shared/component/modal/ae-modal-info/ae-modal-info.component';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { RuleTypes } from 'src/app/shared/constants';
import { Messages } from 'src/app/shared/message';
import { CustomRule } from 'src/app/shared/models/custom-rule/custom-rule';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-program-simulation',
  templateUrl: './program-simulation.component.html',
  styleUrls: ['./program-simulation.component.css']
})
export class ProgramSimulationComponent implements OnInit {

  @ViewChild(AEModalInfoComponent, { static: true })
  private infoModal: AEModalInfoComponent;

  ALLOWED_NUMBER_OF_EVENT_RECORDS = 10;
  eventsToBeSimulated: {
    eventName;
    recordAdded;
    sample;
    schema;
    errors: {type: string; data: string[]};
  }[];
  public eventSchemaMap = new Map<string, EventRecord>();
  programData: ProgramData;
  parentId;
  configuredProgram: ConfiguredProgram = new ConfiguredProgram();
  waitingForResponse = false;
  programEventRules: EventKey[] = [];
  sampleEventRecord;
  eventRecordType: EventKey[];
  addedEventRecordsCount = 0;
  eventSource: string;
  eventType: string;
  private ajv = new Ajv({ allErrors: true });
  private contentId: string;
  private title: string;
  private label: string;
  private content;
  private isTypeJson: boolean;
  isLlmProgram: boolean;
  messages: { text: string, chart: Chart, table:object, type: string, id:string, sender: 'user' | 'api' }[] = []; 
  userInput: string = ''; 
  loading: boolean = false;
  chart;

  constructor(
    public displayText: DisplayTextService,
    public programService: ProgramService,
    private programDataService: ProgramSharedDataService,
    private popupService: PopupMessageService,
    public packagedProgramService: PackagedProgramService,
    public injector: Injector,
    public jsonFileService: JSONFileService,
    public authorizationService: AuthorizationService,
    public http?: HttpClient) {
  }

  ngOnInit(): void {
    this.eventsToBeSimulated = [];
    this.eventRecordType = [];
    this.addNewEventRecord();
    this.programData = this.programDataService.getProgramData();
    this.eventType = this.programData.packagedRuleSettings[0].eventType;
    if (this.eventType.includes('Python-')){
      console.log('THIS IS AN LLM PROGRAM');
      this.isLlmProgram = true;
    } else {
      this.isLlmProgram = false;
    }
    console.log(this.isLlmProgram);
    this.parentId = this.programData.parentId;
    this.configuredProgram = this.programData.tenantProgram;
    this.eventSchemaMap = this.programDataService.getEventSchemaMap();
    if (this.configuredProgram.programType === undefined) {
      this.fetchConfiguredProgramDetails();
    } else {
      this.configureAvailableEventsForProgram();
    }
  }

  // public sendMessage() {
  //   if (this.chart){
  //     this.chart.destroy();
  //   }
  //   if (this.userInput.trim()) {
  //     this.messages.push({ text: this.userInput, sender: 'user', type: 'string', chart: null, table: null });
  //     this.loading = true;
  //     this.postMessage(this.userInput).subscribe(
  //       response => {
  //         const apiMessage = response.assistant_response;
  //         console.log("API Message")
  //         console.log(typeof apiMessage);
  //         if (apiMessage.includes("```json")){
  //           console.log("Includes JSON!");
  //           var json = this.extractJsonFromString(apiMessage);
  //           this.messages.push({text:'', table: null, chart: new Chart("myChart", json), sender:'api', type:'chart'})
  //           this.loading = false;
  //         } else if (this.canBeParsedToList(apiMessage)){
  //           var resultList = JSON.parse(apiMessage);
  //           var strTable = this.formatListAsTableString(resultList);
  //           this.loading = false;
  //           this.messages.push({ text: '', chart: null, table: {}, sender: 'api', type: 'table' });
  //         } else {
  //           this.loading = false;
  //           this.messages.push({ text: apiMessage, sender: 'api', type:'text', chart: null, table: null });
  //         }
  //       },
  //       error => {
  //         this.messages.push({ text: 'Failed to get response from the API.', sender: 'api', type: 'string', chart: null, table: null });
  //         this.loading = false;
  //         console.error('API error:', error);
  //       }
  //     );
  //     this.userInput = '';
  //   }
  // }

  public sendMessage() {
    console.log(this.messages);
    if (this.chart) {
      this.chart.destroy(); // Destroy any existing chart
    }
    if (this.userInput.trim()) {
      this.messages.push({ text: this.userInput, sender: 'user', type: 'string', chart: null, table: null, id:null });
      this.loading = true;
  
      this.postMessage(this.userInput).subscribe(
        response => {
          const apiMessage = response.assistant_response;
          console.log("API Message")
          console.log(apiMessage);
  
          // Handle JSON for charts
          if (apiMessage.includes("```json")) {
            console.log("Includes JSON!");

            var result = this.extractJsonWithSurroundingText(apiMessage);
            
            const { textBefore, json, textAfter } = result;
            if(textBefore != null){
              this.messages.push({ text: textBefore, sender: 'api', type: 'string', chart: null, table: null, id:null })
            }
            const chartId = 'chart-' + (this.messages.length + 1);
            this.messages.push({
              text: '',
              chart: json,
              table: null,
              sender: 'api',
              type: 'chart',
              id: chartId 
            });
            setTimeout(() => {
              new Chart(chartId, json);
            });
            if(textAfter != null){
              this.messages.push({ text: textAfter, sender: 'api', type: 'string', chart: null, table: null, id:null })
            }
            this.loading = false;
  
          // Handle table data
          } else if (apiMessage.includes("https://")) {
            this.messages.push( {text: apiMessage, sender: 'api', type: 'string', chart: null, table: null, id:null});
            var urlArray: string[] = [];
            urlArray = apiMessage.match(/'(.*?)'/g)?.map(url => url.replace(/'/g, '').trim()) || [];
            console.log(urlArray);
            urlArray.forEach(url => {
              console.log(url);
              this.messages.push({
                text: url,
                chart: null,
                table: null,
                sender: 'api',
                type: 'image',
                id: url 
              });
            });
            console.log(this.messages);
            this.loading = false;
          } else if (this.canBeParsedToList(apiMessage)) {
            var resultList = JSON.parse(apiMessage);
            const tableId = 'table-' + (this.messages.length + 1); // Create unique ID for each table
            this.loading = false;
            this.messages.push({
              text: '',
              chart: null,
              table: resultList, // Push the table data
              sender: 'api',
              type: 'table',
              id: tableId // Pass the table ID
            });
  
          } else {
            this.loading = false;
            const formattedMessage = apiMessage.replace(/\n/g, '<br>');
            this.messages.push({ text: formattedMessage, sender: 'api', type: 'string', chart: null, table: null, id:null });
          }
        },
        error => {
          this.messages.push({ text: 'Failed to get response from the API.', sender: 'api', type: 'string', chart: null, table: null, id:null });
          this.loading = false;
          console.error('API error:', error);
        }
      );
      this.userInput = '';
    }
  }

  public canBeParsedToList(str) {
    try {
        const parsed = JSON.parse(str);
        // Check if the parsed object is an array
        if (Array.isArray(parsed)) {
            return true; // It's an array (list)
        } else {
            return false; // It's not an array
        }
    } catch (error) {
        return false; // Error in parsing means it's not a valid JSON or not an array
    }
  }




  public postMessage(userMessage: string): Observable<{ assistant_response: string }> {
    var body = {
      "parentId": "genai",
      "useCase": this.eventType,
      "companyId": "GenAI",
      "source": "JPEventSource",
      "correlationId": "correlationId",
      "eventTimeStamp": 123456789,
      "data": {
          "userMessage": userMessage
      }
    }
    const url = "https://bols1o79lc.execute-api.us-east-1.amazonaws.com/dev/genai/execute";
    const payload = JSON.stringify(body);
    return this.http.post<{ assistant_response: string }>(url, payload);
  }

  public extractJsonWithSurroundingText(ai_response: string) {
    // Regular expression to find the JSON block within the string
    const jsonRegex = /```json\s*([\s\S]*?)\s*```/g;
    const match = jsonRegex.exec(ai_response);
    
    if (match && match[1]) {
        const jsonString = match[1].trim();
        
        // Text before and after the JSON block
        const textBeforeJson = ai_response.substring(0, match.index).trim();
        const textAfterJson = ai_response.substring(match.index + match[0].length).trim();
        
        let jsonObject = null;
        try {
            jsonObject = JSON.parse(jsonString);
        } catch (error) {
            console.error('Invalid JSON string provided:', error);
        }

        // If there is no text before or after, return null for them
        const result = {
            textBefore: textBeforeJson.length > 0 ? textBeforeJson : null,
            json: jsonObject,
            textAfter: textAfterJson.length > 0 ? textAfterJson : null,
        };

        return result;
    }

    console.error('No valid JSON block found.');
    return null;
  }

  public clearMessages() {
    this.messages = []; 
    if (this.chart){
      this.chart.destroy();
    }
  }

  public fetchConfiguredProgramDetails(): void {
    this.programService.getConfiguredProgramDetails(this.programData.parentId, this.programData.programId).subscribe(
      (res: any) => {
        this.configuredProgram = new ConfiguredProgram(res);
        this.programData.tenantProgram = this.configuredProgram;
        this.configureAvailableEventsForProgram();
      }, (error: any) => {
        this.popupService.showErrorMessage(error.message);
      });
  }

  public configureAvailableEventsForProgram(): void {
    this.programEventRules = [];
    if (this.configuredProgram.programType && this.configuredProgram.programType === ProgramType.PACKAGED
        && this.configuredProgram.packagedProgramName) {
      this.packagedProgramService.getProgramDetails(this.parentId, this.programData.eventSource,
        this.configuredProgram.packagedProgramName).subscribe(
        (res: any) => {
          for (const rule of res.result.rules) {
            if (!rule.eventType.endsWith('Reminder') && !rule.eventType.endsWith('Message')) {
              this.programEventRules.push(this.buildEvent(rule.eventType, rule.eventType));
              this.addToEventSchemaMap(rule.eventType);
            }
          }
        });
    } else if (this.configuredProgram.programType === ProgramType.SELF_SERVICE) {
      this.programEventRules.push(this.buildEvent(this.configuredProgram.configuration.eventType,
        this.configuredProgram.configuration.eventType));
    } else if (this.configuredProgram.programType === ProgramType.SELF_SERVICE_EXTERNAL) {
      const rules = JSON.parse(this.configuredProgram.configuration.rules);
      if (rules !== undefined) {
        const eventMap: Map<string, string> = new Map<string, string>();
        rules.forEach(rule => eventMap.set(rule.eventKey.eventName, rule.eventKey.eventSourceName));
        eventMap.forEach((value: string, key: string) => {
          this.programEventRules.push(this.buildEvent(value, key));
          this.addToEventSchemaMap(key);
        });
      }
    } else if (this.configuredProgram.programType === ProgramType.SELF_SERVICE_EXTERNAL_PACKAGED) {
      const rules: CustomRule[] = JSON.parse(this.configuredProgram.configuration.rules);
      const eventSource = rules.find(rule => rule.type === RuleTypes.PACKAGED).eventKey.eventSourceName;
      this.programData.packagedRuleSettings.forEach(packagedRuleSetting => {
        if (!packagedRuleSetting.eventType.endsWith('Reminder') && !packagedRuleSetting.eventType.endsWith('Message')) {
          this.programEventRules.push(this.buildEvent(eventSource, packagedRuleSetting.eventType));
          this.addToEventSchemaMap(packagedRuleSetting.eventType);
        }
      });
    }
  }

  public initializeSampleEventRecordWithParentItems(): void {
    if (this.configuredProgram.programType === ProgramType.PACKAGED) {
      this.sampleEventRecord.parentId = this.parentId;
      this.sampleEventRecord.sourceSystem = this.configuredProgram.configuration.eventSource;
      if (JSON.parse(this.configuredProgram.configuration.programInput).USER_AUTHENTICATED !== undefined) {
        this.sampleEventRecord.userAuthenticated = JSON.parse(this.configuredProgram.configuration.programInput).USER_AUTHENTICATED.value;
      }
      if (this.configuredProgram.configuration.companyId) {
        this.sampleEventRecord.companyId = this.configuredProgram.configuration.companyId;
      }
      if (this.sampleEventRecord.event !== undefined && this.sampleEventRecord.event.operationDate !== undefined) {
        this.sampleEventRecord.event.operationDate = new Date().getTime();
      }
    } else if (this.configuredProgram.programType === ProgramType.SELF_SERVICE_EXTERNAL) {
      this.sampleEventRecord.parentId = this.parentId;
      this.sampleEventRecord.sourceSystem = this.eventSource;
      const rules = JSON.parse(this.configuredProgram.configuration.rules);
      for (const rule of rules) {
        if (this.eventSource === EventSource.CONVERSANT && rule.companyId) {
          this.sampleEventRecord.companyId = rule.companyId;
        }
      }
    } else if (this.configuredProgram.programType === ProgramType.SELF_SERVICE_EXTERNAL_PACKAGED) {
      this.sampleEventRecord.parentId = this.parentId;
      const rules: CustomRule[] = JSON.parse(this.configuredProgram.configuration.rules);
      const packagedRule = rules.find(rule => rule.type === RuleTypes.PACKAGED);
      const eventSource = packagedRule.eventKey.eventSourceName;
      const companyId = packagedRule.companyId;
      this.sampleEventRecord.sourceSystem = eventSource;
      this.sampleEventRecord.companyId = companyId;
    }
  }

  public addNewEventRecord(): void {
    if (this.eventsToBeSimulated.length < this.ALLOWED_NUMBER_OF_EVENT_RECORDS) {
      this.eventsToBeSimulated.push({ recordAdded: false, sample: '', schema: '', errors: null, eventName: '' });
      this.eventRecordType.push(null);
    }
  }

  public discardEventRecordAt(index: number): void {
    if (index >= 0 && index < this.eventsToBeSimulated.length) {
      this.eventsToBeSimulated.splice(index, 1);
      this.eventRecordType.splice(index, 1);
      this.addedEventRecordsCount--;
    }
  }

  public validate(): boolean {
    if (this.validateEventRecords()) {
      if (this.addedEventRecordsCount === 0) {
        this.popupService.showErrorMessage(Messages.programSimulationEventRecordsErrorMessage);
        return false;
      }
      if (this.programData.tenantProgram.active === false) {
        this.popupService.showErrorMessage(Messages.programSimulationNotActiveErrorMessage);
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  public validateEventRecords(): boolean {
    let isValid = true;
    for (const eventRecord of this.eventsToBeSimulated) {
      eventRecord.errors = null;
      if (!this.isEventRecordAdded(eventRecord)) {
        isValid = false;
        continue;
      }
      if (!this.isValidJson(eventRecord)) {
        isValid = false;
        continue;
      }
      if (!this.hasRequiredProperties(eventRecord)) {
        isValid = false;
        continue;
      }
    }
    return isValid;
  }

  public performRunSimulation(): void {
    if (this.validate()) {
      const eventRecordPayload: ProgramSimulationEvent[] = this.eventsToBeSimulated.map(eventRecord => {
        const testEventRecord = JSON.parse(eventRecord.sample);
        return {
          eventName: testEventRecord.eventName,
          eventPayload: JSON.stringify(testEventRecord)
        };
      });
      const eventRecordPayloadStr = JSON.stringify(eventRecordPayload);
      this.waitingForResponse = true;
      this.programService
        .simulateEventsInConfiguredProgram(this.parentId, this.programData.programId, eventRecordPayloadStr)
        .subscribe(
          (value: any) => {
            this.handleSimulationResponse(value.result);
          }, (error: any) => {
            this.handleSimulationResponse(error.error.result);
          }
        ).add(() => {
          this.waitingForResponse = false;
        });
    }
  }

  public handleSimulationResponse(response): void {
    // check if response is string, it is an error message.
    if (typeof response !== 'string') {
      const result = new Map<string, string[]>(Object.entries(response));
      let failureCount = 0;
      result.forEach((v, k) => {
        if (v[0] !== 'success') {
          this.eventsToBeSimulated[k].errors = { type: 'validation', data: v };
          failureCount++;
        }
      });
      const successCount = this.addedEventRecordsCount - failureCount;
      if (failureCount > 0) {
        this.popupService.showErrorMessage(`${successCount} event triggered for simulation. ${failureCount} event failed with validation errors.`);
      } else {
        this.popupService.showSuccessMessage(`${successCount} event successfully triggered for simulation.`);
      }
    } else {
      this.popupService.showErrorMessage(response);
    }
  }

  public open(i: number): void {
    this.contentId = 'eventRecordData';
    this.title = this.eventsToBeSimulated[i].eventName ? this.eventsToBeSimulated[i].eventName : 'Event Record';
    this.label = 'Event Record';
    this.content = this.eventsToBeSimulated[i].sample;
    this.launchModal();
    this.isTypeJson = true;
  }

  public addToEventSchemaMap(eventType: string): void {
    this.eventSchemaMap = this.programDataService.getEventSchemaMap();
    this.sampleEventRecord = JSON.parse(this.eventSchemaMap.get(eventType).sample);
    this.sampleEventRecord.eventName = eventType;
    this.initializeSampleEventRecordWithParentItems();
    this.eventSchemaMap.get(eventType).sample = JSON.stringify(this.sampleEventRecord, undefined, 4);
  }

  public addToSimulateEventList(index: number): void {
    const eventRecordSchema = this.eventSchemaMap.get(this.eventRecordType[index].eventName);
    this.eventsToBeSimulated[index].eventName = this.eventRecordType[index].eventName;
    this.eventsToBeSimulated[index].sample = eventRecordSchema.sample;
    this.eventsToBeSimulated[index].schema = eventRecordSchema.schema;
    this.eventsToBeSimulated[index].recordAdded = eventRecordSchema.recordAdded;
    this.eventsToBeSimulated[index].errors = eventRecordSchema.errors;
    this.addedEventRecordsCount++;
  }

  private launchModal(): void {
    this.infoModal.launchModal({
      'title': this.getTitle(),
      'description': '',
      'content': {
        'type': 'PLAIN_TEXT',
        'data': this.getContent()
      }
    });
  }

  private getTitle(): string {
    return this.title;
  }

  private getContent(): string {
    return this.content;
  }

  private buildEvent(eventSourceName: string, eventName: string) {
    const event = new EventKey(eventSourceName, eventName);
    return event;
  }

  private isValidJson(eventRecord): boolean {
    let isValidJSON: boolean;
    try {
      JSON.parse(eventRecord.sample);
      isValidJSON = true;
    } catch (error) {
      isValidJSON = false;
      eventRecord.errors = { type: 'object', data: null };
    }
    return isValidJSON;
  }

  private isEventRecordAdded(eventRecord): boolean {
    let isRecordAdded: boolean;
    if (eventRecord.recordAdded) {
      isRecordAdded = true;
    } else {
      eventRecord.errors = { type: 'notAdded', data: null };
      isRecordAdded = false;
    }
    return isRecordAdded;
  }

  private hasRequiredProperties(eventRecord) {
    let hasRequiredProperties = true;
    const parsedEventRecord = JSON.parse(eventRecord.sample);
    const eventSchema = JSON.parse(eventRecord.schema);
    const validate = this.ajv.compile(eventSchema);
    const valid = validate(parsedEventRecord);
    if (!valid) {
      const requiredProperyErrors = validate.errors.filter(error => error.keyword === 'required');
      if (requiredProperyErrors && requiredProperyErrors.length > 0) {
        hasRequiredProperties = false;
        eventRecord.errors = { type: 'required', data: [] };
        requiredProperyErrors.forEach(requiredProperyError => {
          eventRecord.errors.data.push(requiredProperyError.params['missingProperty']);
        });
      }
    }
    return hasRequiredProperties;
  }

}
